$font-path: '../../public/fonts';

@font-face {
    font-family: 'Gotham';
    src: url('#{$font-path}/gotham/Gotham-Light.woff') format('woff'),
        url('#{$font-path}/gotham/Gotham-Light.ttf') format('truetype'),
        url('#{$font-path}/gotham/Gotham-Light.svg#Gotham-Light') format('svg');

    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Gotham';
    src: url('#{$font-path}/gotham/Gotham-Book.woff') format('woff'),
        url('#{$font-path}/gotham/Gotham-Book.ttf') format('truetype'),
        url('#{$font-path}/gotham/Gotham-Book.svg#Gotham-Book') format('svg');

    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Gotham';
    src: url('#{$font-path}/gotham/Gotham-Medium.woff') format('woff'),
        url('#{$font-path}/gotham/Gotham-Medium.ttf') format('truetype'),
        url('#{$font-path}/gotham/Gotham-Medium.svg#Gotham-Medium') format('svg');

    font-weight: 500;
    font-style: normal;
    font-display: block;
}