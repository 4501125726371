.news-feed {
    display: flex;
    flex: 2;
    overflow: hidden;

    &--is-slided {
        background: var(--c-white);
    }

    &__block-slider {
        display: flex;
        flex: 1;
    }

    &__news-slider-one {
        animation: var(--news-slide-duration) vertical-news-slide-left linear infinite;
    }

    &__news-slider-two {
        animation: var(--news-slide-duration) vertical-news-slide-right linear infinite;
    }
}