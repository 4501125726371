@import "src/styles";

.news-slider {
    flex: 1;
    padding-top: 20rem;

    &__block-wrapper {
        opacity: 0;

        &--is-visible {
            animation: 1.5s vertical-news-item-fade-in cubic-bezier(.5, 1, .75, 1) forwards;
            transform: translateY(24rem);
        }
    }

    &__image-wrapper {
        display: flex;
        position: relative;
        margin: 2rem 1rem;
        border-radius: 1rem;
        min-height: 20vh;
        box-shadow: var(--box-shadow);
        background-color: var(--c-red);

        &--has-image {
            background-color: unset;
        }

        @include for-size(desktop-up) {
            margin: 4rem 2rem;
        }

        @include for-size(biggest-desktop-up) {
            margin: 6rem 3rem;
            border-radius: 1.5rem;
        }
    }

    &__image {
        border-radius: 1rem;
        width: 100%;
        height: 100%;
        min-height: 20vh;
        max-height: 50vh;
        object-fit: cover;

        @include for-size(biggest-desktop-up) {
            border-radius: 1.5rem;
        }
    }

    &__title-wrapper {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 1.5rem;
        width: 100%;

        @include for-size(biggest-desktop-up) {
            padding: 3rem;
        }
    }

    &__title {
        margin-right: 1rem;
        margin-bottom: -.625rem;
        font-size: 1.75rem;

        @include for-size(desktop-up) {
            font-size: 2.5rem;
        }

        @include for-size(biggest-desktop-up) {
            margin-right: 2.5rem;
            font-size: 5.5rem;
        }
    }

    &__qr-code-wrapper {
        display: flex;
    }

    &__qr-code {
        @include square(5rem);

        padding: .75rem;

        @include for-size(big-desktop-up) {
            @include square(8rem);

            padding: 1rem;
        }

        @include for-size(biggest-desktop-up) {
            @include square(12rem);

            padding: 1.25rem;
        }
    }
}