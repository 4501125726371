.block-slider {
    flex: 1;
    display: flex;
    flex-direction: column;

    &__item {
        flex: 1;
        width: 0;
        background: var(--c-white);
        animation: block-slide-in var(--slide-duration) ease-in-out forwards;
        animation-delay: var(--initial-slide-delay);
    }
}