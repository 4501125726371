.videowall-container {
    display: flex;
    width: 100%;
    height: 100vh;

    &__picture {
        position: absolute;
        width: 100%;
        z-index: -10;
    }

    &__image {
        position: fixed;
        width: 100%;
    }
}