@import "src/styles";

.news-room {
    display: flex;
    flex: 1;
    position: relative;
    overflow: hidden;


    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;

        transform: translateX(-100%);
        background-image: url("../../../public/news-room-bg.webp");
        background-size: cover;
        animation: bg-slide-in var(--news-room-background-duration) cubic-bezier(1, 0, .5, 1.1) forwards;
        animation-delay: var(--initial-background-delay);
    }

    &__block-container {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    &__sliding-block {
        position: relative;
        flex: 1;
        width: 100%;
        background: var(--c-white);
        transform: translateX(-100%);
        animation: block-slide-in-out var(--slide-duration) forwards;
        animation-timing-function: cubic-bezier(.5, 0, .7, .85);
        animation-delay: var(--initial-slide-delay);
    }

    &__context {
        position: absolute;
        bottom: 0;
        padding: 2.5rem 3rem;

        @include for-size(biggest-desktop-up) {
            padding: 5rem;
        }
    }

    &__title {
        color: var(--c-white);
        font-size: 1.25rem;
        font-weight: var(--fw-bold);

        @include for-size(desktop-up) {
            font-size: 1.75rem;
        }

        @include for-size(biggest-desktop-up) {
            font-size: 3rem;
        }
    }

    &__title-span {
        display: inline-flex;
        margin-bottom: .5rem;
        opacity: 0;
        animation: fade-in .5s forwards;
        animation-delay: calc(var(--title-initial-delay) + var(--title-letter-delay));
    }

    &__sub-title {
        font-size: 4rem;
        font-weight: var(--fw-lighter);

        @include for-size(desktop-up) {
            font-size: 6rem;
        }

        @include for-size(biggest-desktop-up) {
            font-size: 10rem;
        }

        &:first-of-type {
            margin-top: 1.5rem;
        }
    }

    &__sub-title-span {
        display: inline-block;
        opacity: 0;
        white-space: pre-wrap;
        animation: fade-in .75s forwards;
        animation-delay: calc(var(--sub-title-initial-delay) + var(--sub-title-letter-delay));
    }

    &__link-wrapper {
        margin: 5rem 0 3.5rem;

        @include for-size(biggest-desktop-up) {
            margin: 7.5rem 0;
        }
    }

    &__link {
        padding: .75rem;
        opacity: 0;
        animation: fade-in 2.25s forwards;
        animation-delay: var(--link-initial-delay);
    }
}