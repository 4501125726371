@import "animations";
@import "fonts";
@import "variables";
@import "src/styles";

* {
    box-sizing: border-box;
}

:focus:not(:focus-visible) {
    outline: none;
}

html {
    font-size: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--f-primary);
    font-weight: var(--fw-regular);
    font-size: 2rem;
    -webkit-font-smoothing: antialiased;
    color: var(--c-black);
    background-color: var(--c-white);

    &.has-active-modal {
        overflow: hidden;
    }
}

h1, h2, h3 {
    margin: 0;
    color: var(--c-white);
}

h1 {
    font-family: var(--f-primary);
    font-weight: var(--fw-lighter);
    line-height: 1.125;
}

h2 {
    font-weight: var(--fw-lighter);
    line-height: 1;
}

h3 {
    font-family: var(--f-primary);
    font-weight: var(--fw-bold);
}

p {
    margin: 0;
}

a {
    text-decoration: none;
}

#app-root,
#portal-root {
    position: relative;
    z-index: 10;
}

#portal-root {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
}
